import { IonContent, IonIcon, IonItem, IonList, IonPopover, useIonModal } from "@ionic/react";
import styles from "./DeviceTransitPolicyTile.module.css";
import { useDeviceTransitPolicyActivateMutation, useDeviceTransitPolicyQuery } from "../query/deviceTransitPolicyQuery";
import { TransitPolicy } from "../models/TransitPolicy";
import useUX from "../utils/ux";
import DoorPolicyModal from "../modals/DoorPolicyModal";

interface ContainerProps {
  deviceTransitPolicyId: number;
  active: boolean;
}

function lockIconNameForTransitPolicy(transitPolicy?: TransitPolicy) {
  if (!transitPolicy?.rules?.some(rule => rule.action?.lock === false)) {
    //If no rule can unlock, this is a remain locked policy
    return 'lock-locked';
  }

  if (transitPolicy?.rules?.some(rule => rule.criteria)) {
    //If any rule has criteria, this is a smart lock policy
    return 'lock-smart';
  }

  return 'lock-unlocked';
}

const DeviceTransitPolicyTile: React.FC<ContainerProps> = ({ deviceTransitPolicyId, active }) => {
  const id = 'DeviceTransitPolicyTile' + deviceTransitPolicyId;
  const query = useDeviceTransitPolicyQuery(deviceTransitPolicyId);
  const activateMutation = useDeviceTransitPolicyActivateMutation(query.data?.deviceId!);
  const { presentResult } = useUX();
  const [presentEditModal, dismissEditModal] = useIonModal(DoorPolicyModal, {
    dismiss: (data: string, role: string) => dismissEditModal(data, role),
    deviceTransitPolicyId
  });

  const activate = async () => {
    presentResult(() => activateMutation.mutateAsync(deviceTransitPolicyId), "Door Policy Activated");
  }

  return (
    <button id={id} className={[styles.tile, active ? styles.active : styles.inactive, activateMutation.isPending && styles.activating].join(' ')} >
      <IonIcon src={`/assets/lock/${lockIconNameForTransitPolicy(query.data?.transitPolicy)}.svg`} />
      <label>{query.data?.name}</label>
      <IonPopover trigger={id} dismissOnSelect>
        <IonContent>
          <IonList>
            <IonItem button={true} detail={false} disabled={active} onClick={activate}><strong>Activate</strong></IonItem>
            <IonItem button={true} detail={false} onClick={() => presentEditModal()}>Edit Policy...</IonItem>
          </IonList>
        </IonContent>
      </IonPopover>
    </button>
  );
};

export default DeviceTransitPolicyTile;